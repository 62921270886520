.treatment-irrigation-host {
  display: inline-block;
  margin-right: 15px;
  width: calc(100% / 3 - 15px);
}

.treatment-fertilizer-container,
.treatment-irrigation-container,
.treatment-pesticide-container,
.treatment-seed-container {
  margin-bottom: 0;
}

.treatment-pesticides-host,
.treatment-seeds-host,
.treatment-fertilizers-host {
  @include media-breakpoint-up(xl) {
    flex: 1;
  }

  .form-group {
    margin-bottom: 0;
  }
}

.treatment-pesticide-host,
.treatment-seed-host {
  @include media-breakpoint-up(xl) {
    width: 20%;
  }

  label span {
    white-space: nowrap;
  }
}

.treatment-fertilizer-host {
  @include media-breakpoint-up(xl) {
    width: 25%;
  }

  label span {
    white-space: nowrap;
  }
}

.treatment-pesticides-buttons,
.treatment-seeds-buttons,
.treatment-fertilizers-buttons {
  @include media-breakpoint-up(xl) {
    margin-left: 15px;
    flex-wrap: nowrap;
  }

  .btn {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 100px;
    }

    @include media-breakpoint-up(xl) {
      width: auto;
      margin-bottom: 0;
    }
  }

  i {
    &:before {
      color: #fff;
    }
  }
}

#treatmentPesticidesArrayModel,
#treatmentSeedsArrayModel,
#treatmentFertilizersArrayModel {
  & > div {
    @include media-breakpoint-up(xl) {
      display: flex;
      align-items: flex-end;
    }
  }
}

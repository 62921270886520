.badge {
  padding: 6px 10px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 6px;

  &-success {
    color: darken($color-primary, 20%);
    background-color: lighten($color-primary, 35%);
  }

  &-info {
    color: $color-info;
    background-color: lighten($color-info, 45%);
  }

  &-warning {
    color: $color-warning;
    background-color: lighten($color-warning, 45%);
  }

  &-danger {
    color: $color-danger;
    background-color: lighten($color-danger, 21%);
  }
}

.badge-pill {
  padding: 5px 6px;
}

.badge-section {
  &-general {
    background-color: #ffd966;
  }

  &-waste {
    background-color: #ddebf7;
  }

  &-ddd {
    background-color: #bdd7ee;
  }

  &-transport {
    background-color: #8ea9db;
  }

  &-production {
    background-color: #ffe699;
  }

  &-healthandsafety {
    background-color: #00b0f0;
  }

  &-globalgap {
    background-color: #e3a1e0;
  }

  &-buildings {
    background-color: #b59ae9;
  }

  &-recommendations {
    background-color: #a5dee7;
  }
}

.container-limit {
  display: block;
  width: 100%;
  max-width: 960px;

  &-sm {
    display: block;
    width: 100%;
    max-width: 660px;
  }
}

app-form label span.required::after,
app-add-form label span.required::after,
app-search-form label span.required::after,
span.required::after {
  color: #ea0000;
  content: '*';
  left: 2px;
  position: relative;
}

.form-group-legend {
  font-size: 0.75rem;
}

fieldset:disabled {
  ::-webkit-input-placeholder {
    color: transparent !important;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: transparent !important;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: transparent !important;
  }

  :-ms-input-placeholder {
    color: transparent !important;
  }
}

dynamic-ng-bootstrap-datepicker > div > div > button > img {
  height: 1rem;
  width: 1.2rem;
}

// icon for app-form and app-add-form components

// ft-home
.ft-addressGroup:before,
.ft-contactBaseGroup:before,
.ft-documentBaseGroup:before,
.ft-noteBaseGroup:before,
.ft-organizationBaseGroup:before,
.ft-basicInformationGroup:before {
  content: '\e965';
}

//ft-thumb-up
.ft-qualityParametersGroup:before {
  content: '\e9c4';
}

// ft-tag
.ft-organizationDetailGroup:before {
  content: '\e9c0';
}

// .ft-image
.ft.ft-target.ft-notePhotosGroup:before {
  content: '\e966';
}

// ft-file-text
.ft-documentAttachmentGroup:before {
  content: '\e958';
}

form {
  .form-control:not([type='radio']):not([type='checkbox']) {
    min-height: $btn-input-height;
    font-size: 13px;
    border-radius: $border-radius;
    border-color: $input-bg;
    background: $input-bg;
    padding: 10px;

    &::placeholder {
      font-size: 13px;
      color: rgba(58, 64, 79, 0.3);
    }
    &:focus {
      border-color: $color-primary !important;
      box-shadow: none;
    }

    &:disabled,
    &.disabled {
      background: $input-bg-disabled;
    }
  }

  select {
    min-height: $btn-input-height;
  }

  label {
    color: $color-text-primary;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.05px;
    text-transform: none;
  }

  input[type='radio'],
  input[type='checkbox'] {
    height: auto;
  }

  .ng-select {
    font-size: 13px;

    &:focus {
      border-color: $color-primary;
      box-shadow: none;
    }
    .ng-placeholder {
      font-size: 13px;
      color: rgba(58, 64, 79, 0.3) !important;
      top: auto !important;
    }

    .ng-select-container {
      color: #75787d;
      border-radius: $border-radius;
      border-color: $input-bg;
      background: $input-bg;
      height: $btn-input-height !important;

      &:hover {
        box-shadow: none;
      }

      .ng-input {
        top: 8px !important;

        & > input {
          padding-inline: 0;
        }
      }
    }

    &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
      border-color: $color-primary;
      box-shadow: none;
    }

    &.ng-select-multiple {
      .ng-select-container {
        height: auto !important;
        min-height: 42px;

        .ng-value-container {
          .ng-value {
            padding: 2px 4px;
            border-radius: 6px;
            word-break: break-word;
            white-space: normal;
          }
        }
      }
    }

    .ng-clear-wrapper:hover .ng-clear {
      color: $color-text-primary;
    }
  }

  .ng-select.ng-select-disabled > .ng-select-container {
    background: $input-bg-disabled;
  }

  dynamic-ng-bootstrap-datepicker {
    .input-group-append {
      button {
        box-shadow: none;
        background-color: $input-bg;
        border-radius: $border-radius;
        height: $btn-input-height;
        aspect-ratio: 1;

        &:hover {
          background-color: $input-bg;
          border-color: $input-bg;

          &:after {
            color: $color-primary;
          }
        }

        &:active {
          background-color: $input-bg !important;
        }

        &:after {
          content: '\e10a';
          font-family: 'Phosphor' !important;
          font-size: 20px;
          color: $color-text-primary;
        }

        img {
          display: none;
        }

        &:disabled {
          background-color: $input-bg-disabled;
          border-color: $input-bg-disabled;
        }
      }
    }
  }
}

.filter-checkbox {
  margin-top: 38px;
}

.btn {
  height: $btn-input-height;
  line-height: 20px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 16px;
  border-radius: $border-radius;
  border: 1px solid transparent;

  i {
    font-size: 20px;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: $color-text-tetritary;
    background: transparent;
    border-color: $color-border-tetritary;
    box-shadow: none;
    pointer-events: none;
    opacity: 1;

    i {
      color: $color-icon-tetritary;
    }
  }

  &-primary {
    background: $gradient-primary;
    border-color: #77ebbc;
    box-shadow: $box-shadow-primary;
    color: $color-text-primary !important;

    &:hover {
      background: $gradient-primary-inverse;
      border-color: #77ebbc;
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px transparentize($color-primary, 0.2);
    }

    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
    }

    i {
      color: $color-text-primary;
    }

    &.disabled,
    &:disabled {
      color: $color-gray-400;
      background: $color-gray-400;
      border-color: $color-gray-400;
      box-shadow: none;

      i {
        color: $color-text-primary;
      }
    }
  }

  &-primary-outline {
    background: $color-white;
    border-color: $color-primary;
    color: $color-text-primary;

    &:hover,
    &.active {
      background: tint($color-primary, 85%);
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px transparentize($color-primary, 0.2);
    }

    i {
      color: $color-primary;
    }
  }

  &-secondary {
    background: $color-secondary-light;
    border-color: $color-secondary;
    color: $color-text-primary;

    &:hover {
      background: tint($color-secondary, 50%);
      border-color: $color-secondary;
      color: $color-text-primary;
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px transparentize($color-secondary, 0.2);
    }

    &:not(:disabled):not(.disabled):active {
      background: $color-secondary-light;
      border-color: $color-secondary;
      color: $color-text-primary;
    }

    i {
      color: $color-text-primary;
    }
  }

  &-negative {
    background: $color-surface-negative-1;
    border-color: $color-danger;
    color: $color-text-primary;

    &:hover {
      background: tint($color-danger, 75%);
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px transparentize($color-danger, 0.2);
    }

    i {
      color: $color-danger;
    }
  }

  &-action {
    background: $color-background-primary !important;
  }

  &-link {
    display: inline-flex;
    color: $color-primary;
    text-underline-offset: 6px;
    border-radius: 0;
    padding: 0;
    height: auto;

    &:hover {
      color: $color-primary;
      border-bottom: 1px solid;
      text-decoration: none;
    }

    &:focus-visible {
      color: $color-primary;
      border-bottom: 1px solid;
      text-decoration: none;
    }

    &:active {
      color: $color-primary;
      border-color: transparent;
    }

    &.disabled,
    &:disabled {
      border-color: transparent;
    }
  }

  &-icon {
    aspect-ratio: 1;
    padding: 4px;

    &.disabled,
    &:disabled {
      border-color: transparent;
    }
  }

  &-sm {
    height: 24px;
  }

  &-round {
    border-radius: 50%;
  }

  &-table-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    border: 1px solid transparent;
    background: $gradient-primary;
    border-color: #77ebbc;
    box-shadow: $box-shadow-primary;
    color: $color-text-primary !important;

    i {
      font-size: 14px;
    }

    &-danger {
      background: $color-secondary-light;
      border-color: $color-secondary;
      color: $color-text-primary;

      &:hover {
        background: tint($color-secondary, 50%);
        border-color: $color-secondary;
        color: $color-text-primary;
      }

      &:focus-visible {
        box-shadow: 0 0 0 2px transparentize($color-secondary, 0.2);
      }

      &:not(:disabled):not(.disabled):active {
        background: $color-secondary-light;
        border-color: $color-secondary;
        color: $color-text-primary;
      }

      i {
        color: $color-text-primary;
      }
    }

    &:hover {
      background: $gradient-primary-inverse;
      border-color: #77ebbc;
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px transparentize($color-primary, 0.2);
    }

    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
    }
  }
}

.btn-back {
  width: 28px;
  height: 28px;
  border: none;
  border-radius: 50%;
  background: #d8d9dc;
  flex-shrink: 0;

  &:hover {
    background: #cdcdcd;
  }

  i {
    color: #343330;
    font-size: 18px;
  }
}

// Progress Bar
ngb-progressbar.progress-bar-md {
  .progress {
    height: 0.5rem;
  }
}
ngb-progressbar.progress-bar-sm {
  .progress {
    height: 0.25rem;
  }
}

.location-map-marker-tooltip-image {
  max-width: 100% !important;
  max-height: 100px;
}

.marker-content,
.polygon-content {
  width: 100%;
}

.moisture-sensor-marker-content,
.weather-station-marker-content {
  .hidden {
    display: none !important;
  }
}

.battery {
  border: 1px solid #e4e7eb;
  height: 27px;
  margin-top: 4px;
  position: relative;

  .level {
    height: calc(100% - 2px);
    margin: 1px;
    display: inline-block;
    width: calc(25% - 2px);
    opacity: 0.8;
  }

  .label {
    position: absolute;
    top: 5px;
    width: 100%;
    text-align: center;
    font-weight: bold;
  }

  &.battery-1 {
    .level {
      background: red;
    }
  }

  &.battery-2 {
    .level {
      background: orange;
    }
  }

  &.battery-3 {
    .level {
      background: #eded00;
    }
  }

  &.battery-4 {
    .level {
      background: yellowgreen;
    }
  }
}

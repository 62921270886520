@import 'components/commissioned-audit-result';
@import 'components/form';
@import 'components/map';
@import 'components/navbar-fullscreen';
@import 'components/observations';
@import 'components/periods';
@import 'components/product-delivery';
@import 'components/supplier-awards';
@import 'components/table';
@import 'components/treatments';
@import 'components/rating';
@import 'components/service-card';
@import 'components/acl';
@import 'components/alarm-conf';
@import 'components/email';
@import 'components/employee';

form .form-actions {
  padding-bottom: 0;
}

.optional-text:after {
  content: '\200b';
}

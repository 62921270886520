.alert {
  padding: 12px;
  border-radius: $border-radius;
  border: none;
  color: $color-text-primary;

  i {
    font-size: 16px;
  }

  &-info {
    background-color: $color-surface-brand-2 !important;
    color: $color-text-brand-1 !important;
  }

  &-success {
    color: #155724 !important;
    background-color: #d4edda !important;
    border-color: #c3e6cb !important;
  }
  &-danger {
    color: #721c24 !important;
    background-color: #f8d7da !important;
    border-color: #f5c6cb !important;
  }
  &-warning {
    color: #856404 !important;
    background-color: #fff3cd !important;
    border-color: #ffeeba !important;
  }
}

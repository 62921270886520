ngb-datepicker.dropdown-menu {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  padding: 20px;

  .ngb-dp-header {
    padding: 8px 4px 4px;
    border-radius: $border-radius $border-radius 0 0;
  }

  .ngb-dp-weekdays,
  .ngb-dp-header {
    background-color: $color-gray-300;
  }

  .ngb-dp-week-number,
  .ngb-dp-weekday {
    font-style: normal;
  }

  .ngb-dp-arrow-btn:hover {
    border-bottom: none;
  }

  .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday,
  [ngbDatepickerDayView] {
    width: 36px;
    height: 36px;
    line-height: 36px;
  }

  .ngb-dp-weekday {
    color: $color-text-primary;
    font-weight: bold;
  }

  .btn-link {
    color: $color-primary;
    margin: 0;
    padding: 7px;
  }
}

.card {
  background: $color-background-primary;
  border-radius: $border-radius;
  margin: 0;
  box-shadow: $box-shadow;

  .card-body {
    padding: 24px;
  }

  &-header {
    h5 {
      display: flex;
      align-items: center;
      font-weight: 500;
      i {
        margin-right: 10px;
      }
    }
  }
}

.card-shadow {
  background: $color-white;
  padding: 16px;
  border-radius: $border-radius;
  box-shadow: $box-shadow-sm;
}

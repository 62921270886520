#supplierAwardsArrayModel {
  & > div {
    @include media-breakpoint-up(xl) {
      display: flex;
      align-items: flex-top;
    }
  }
}

.supplier-awards-host {
  @include media-breakpoint-up(xl) {
    flex: 1;
  }

  .form-group {
    margin-bottom: 0;
  }
}

.supplier-awards-buttons {
  @include media-breakpoint-up(xl) {
    margin-left: 15px;
    padding-top: 30px;
    flex-wrap: nowrap;
  }

  .btn {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 100px;
    }

    @include media-breakpoint-up(xl) {
      width: auto;
      margin-bottom: 0;
    }
  }

  i {
    &:before {
      color: #fff;
    }
  }
}

.supplier-award-host {
  @include media-breakpoint-up(xl) {
    width: calc(100% / 3);
  }

  label span {
    white-space: nowrap;
  }
}

.supplier-award-container {
  margin-bottom: 0;
}

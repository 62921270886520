.navbar {
  z-index: 3; // required to make the shadow overlap the below content (need is visible in mobile drawer overlay)
  border: 0;
  border-radius: 0;

  &.header-navbar {
    @include media-breakpoint-down(md) {
      padding: 0 24px;
      position: relative;
    }

    @include media-breakpoint-up(lg) {
      padding: 24px 0px;
    }
  }

  .nav-item {
    line-height: normal;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
      margin: 0 5px;
    }

    @include media-breakpoint-down(xs) {
      margin: 0;
    }
  }

  .navbar-brand {
    position: relative;
  }

  .nav-link {
    height: 40px;
    display: flex;
    align-items: center;
  }

  .navbar-collapse {
    padding-bottom: 10px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
    }
  }

  .navbar-nav {
    display: flex;
    gap: 4px;

    @include media-breakpoint-down(md) {
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
    }

    @include media-breakpoint-down(xs) {
      justify-content: space-between;
    }

    .dropdown-menu {
      position: absolute;
    }
  }

  .navbar-toggle {
    background-color: transparent;
    border: none;

    i {
      color: #3a404f;
      font-size: 26px;
    }

    .icon-bar {
      background-color: inherit;
      border: 1px solid;

      & + .icon-bar {
        margin-top: 4px;
      }
    }
  }

  .notification {
    position: absolute;
    top: -1px;
    right: 13px;
  }
}

@media (max-width: 767px) {
  //For responsive mega menu
  #navbar-mobile.navbar-collapse {
    .navbar-nav {
      margin: 0;
      flex-flow: row wrap;

      .dropdown-menu {
        position: absolute;
      }

      .nav-item {
        float: left;
      }
    }
  }
}

/* For sm screen*/
@include media-breakpoint-down(md) {
  .navbar-header {
    width: 100% !important;
  }

  .navbar-container {
    position: absolute;
    top: 15px;
    right: 25px;
  }
}

// Media queries for device support
// =========================================
@include media-breakpoint-down(md) {
  .header-navbar {
    .navbar-container {
      ul.navbar-nav {
        li {
          float: right !important;

          > a.nav-link {
            padding: 0.5rem 0.5rem;
          }
        }
      }
    }
  }
}

[dir='rtl'] {
  @include media-breakpoint-down(md) {
    .header-navbar {
      .navbar-container {
        ul.navbar-nav {
          li {
            float: left !important;
          }
        }
      }
    }
  }
}

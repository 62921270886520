// sidebar
.app-sidebar {
  box-shadow: none;

  .sidebar-header {
    .logo {
      height: 68px;

      @include media-breakpoint-down(md) {
        height: 60px;
      }
    }
  }
}

.header-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  min-height: 45px;
  margin-bottom: 20px;

  h1 {
    word-break: break-word;
  }

  @include media-breakpoint-up(lg) {
    margin-right: $header-navbar-width;
  }
}

.header-menu {
  @include media-breakpoint-up(lg) {
    width: $header-navbar-width;
    position: absolute;
  }
}

@include media-breakpoint-down(md) {
  .logo-farmportal {
    position: relative;
  }
}
.logo-farmportal {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
  max-width: 90px;
}

.nav-toggle,
.nav-close {
  right: auto;
  left: 1rem;
}

.nav-close {
  font-size: 1.5rem;
  top: 50%;
  transform: translate(0, -50%);
}

.btn-danger {
  color: #fff !important;
  background: linear-gradient(to right, #d33838, #fc6262);
}

.section-header {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 8px;
  border-bottom: 1px solid $color-gray-100;
  margin-bottom: 24px;
}

img {
  display: block;
  max-width: 100%;
}

.card-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(250px, 350px), 1fr));
  gap: 24px;
}

p {
  letter-spacing: inherit;
}

.color {
  &-primary {
    color: $color-primary;
  }
  &-secondary {
    color: $color-secondary;
  }
  &-danger {
    color: $color-danger;
  }
  &-text-primary {
    color: $color-text-primary;
  }
}

.icon-size {
  &-16 {
    font-size: 16px !important;
  }
  &-20 {
    font-size: 20px !important;
  }
  &-22 {
    font-size: 22px !important;
  }
  &-24 {
    font-size: 24px !important;
  }
}

.rounded {
  border-radius: $border-radius !important;
}

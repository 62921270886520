app-loading-spinner {
  .circle-spinner {
    border-left-color: rgba(244, 78, 154, 1);
    border-top-color: rgba(247, 148, 29, 1);
  }
  .spinner-backdrop {
    opacity: 0.2 !important;
    border-radius: $border-radius;
  }
}

.commissioned-audit-result-container {
  margin-bottom: 0;
}

.commissioned-audit-result-host {
  @include media-breakpoint-up(xl) {
    width: calc(100% / 3);
  }

  label span {
    white-space: nowrap;
  }
}

.navbar {
  z-index: 1000;
}

.app-sidebar {
  .sidebar-header {
    .logo {
      height: auto;
    }
  }
}

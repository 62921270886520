@mixin text-truncate($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

$gaps: (
  4: 4px,
  6: 6px,
  8: 8px,
  10: 10px,
  12: 12px,
  16: 16px,
);

@mixin gap-generator {
  @each $name, $value in $gaps {
    &-#{$name} {
      gap: $value;
    }
  }
}

.gap {
  @include gap-generator;
}

@function tint($color, $percentage) {
  @return mix(#fff, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(#000, $color, $percentage);
}

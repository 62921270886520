.dropdown {
  .dropdown-toggle {
    &::after {
      font-family: 'Phosphor';
      content: '\e136';
      border: none;
      transform: rotate(0);
      transition: transform 0.3s;
    }
  }
  &.show {
    .dropdown-toggle {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
}

ng-dropdown-panel.ng-dropdown-panel {
  .ng-option {
    color: $color-text-primary;

    &.ng-option-selected {
      font-weight: bold;
      background: none !important;
      position: relative;
    }
    &.ng-option-marked {
      background: $color-gray-300 !important;
    }
  }

  &.ng-select-multiple {
    .ng-option-selected {
      &:after {
        content: '\e182';
        font-family: 'Phosphor' !important;
        color: $color-primary;
        font-size: 16px;
        position: absolute;
        right: 10px;
        top: 8px;
      }
    }
  }
}

/* You can add global styles to this file, and also import other style files */
@import '../apex-template/assets/sass/app';
@import '../farmcloud-template/scss/farmcloud';

//Abstrats
@import 'abstracts/variables';
@import 'abstracts/mixins';

//Core
@import 'core/core';
@import 'core/typhography';

//Pages
@import 'pages/content';

//Components
@import 'components/form';
@import 'components/modal';
@import 'components/tabs';
@import 'components/common';
@import 'components/alerts';
@import 'components/sidebar';
@import 'components/buttons';
@import 'components/table';
@import 'components/card';
@import 'components/spinner';
@import 'components/quill-editor';
@import 'components/datepicker';
@import 'components/dropdown';
@import 'components/badge';
@import 'components/dashboard';
@import 'components/map';
@import 'utils/index';

/* workaround https://github.com/udos86/ng-dynamic-forms/issues/915 */
form.ng-dynamic-form.ng-valid input.ng-touched.ng-invalid,
form.ng-dynamic-form.ng-untouched input.ng-touched.ng-invalid {
  border: 1px solid #a6a9ae;
}

lightbox,
.lightbox {
  z-index: 1060;
}

button.calendar,
button.calendar:active {
  width: 2.75rem;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=') !important;
  background-repeat: no-repeat;
  background-size: 23px;
  background-position: center;
}

.main-spinner > app-loading-spinner > div.backdrop {
  position: fixed !important;
}

.headline-underline {
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 8px;
  border-bottom: 1px solid $color--dark;
  margin-bottom: 24px;
}

.thumbnail {
  width: 100px;
  border-radius: 8px;

  &-sm {
    width: 96px;
    height: 72px;
    object-fit: cover;
  }
}

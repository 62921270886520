//colors
$color-primary: #44d4b1;
$color-primary-light: #a4ffd94d;
$color-secondary: #fdbd82;
$color-secondary-light: #ffebd9;
$color-secondary-light-2: #ffd9b5;
$color-danger: #f4869a;
$color-info: #319dcb;
$color-warning: #c58e25;
$color-white: #fff;
$color-black: #3a404f;
$color-gray: #dddddd;
$color-gray-100: #d6d6d6;
$color-gray-200: #ececec;
$color-gray-300: #f6f6f6;
$color-gray-400: #9da0a7;
$color-gray-500: #f1f1f1;

$color-text-primary: $color-black;
$color-text-secondary: $color-gray-200;
$color-text-brand-1: #06646b;

$color-text-tetritary: #9da0a7;
$color-border-tetritary: #d8d9dc;
$color-icon-tetritary: #b0b3b9;

$color-background-primary: #fcfcfc;

$color-surface-brand-2: #e1f9fb;
$color-surface-negative-1: #fef5f7;
$color-surface-negative-2: #feecf0;
$color-surface-negative-3: #fba7b6;

$box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
$box-shadow-sm: 0 2px 8px rgba(0, 0, 0, 0.08);
$box-shadow-primary: 2px 2px 20px 0 rgba(146, 255, 209, 0.6);
$box-shadow-secondary: 2px 2px 20px 0 rgba(253, 189, 130, 0.2);

$gradient-primary: linear-gradient(180deg, #8ff8bf -15.55%, #51dbb6 157.7%, #44d4b1 157.7%);
$gradient-primary-inverse: linear-gradient(0deg, #8ff8bf -15.55%, #51dbb6 157.7%, #44d4b1 157.7%);
$gradient: linear-gradient(179deg, #8ff8bf -15.55%, #51dbb6 157.7%, #44d4b1 157.7%);
$gradient-hover: linear-gradient(52deg, rgba(244, 78, 154, 0.95) 0%, rgba(247, 148, 29, 0.95) 100%);

//fonts
$font-family: 'Inter', sans-serif;

//others
$border-radius: 8px;
$border-gray: 1px solid $color-gray-200;
$card-border-color: rgba(0, 0, 0, 0.125);

//header navbar
$header-navbar-width: 150px;

//form controls
$btn-input-height: 42px;
$input-bg: $color-gray-300;
$input-bg-disabled: $color-gray-500;

.acl-permissions-host {
  display: inline-block;
  margin-right: 15px;
  width: calc(14.2% - 15px);
}

.acl-permissions-container {
  margin-bottom: 0;
}

.acl-permissions-host {
  display: inline-block;
  margin-right: 0;
  width: calc(100% - 90px);

  .description {
    font-size: smaller;
  }

  .form-group {
    margin-bottom: 0;
  }
}

.acl-permissions-buttons {
  float: right;
  position: relative;
  width: 90px;

  .btn {
    color: white !important;
    position: relative;
    top: 31px;

    &:last-child {
      margin-left: 10px;
    }
  }
}

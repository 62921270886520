.form-layout-group-header-hidden > label,
.form-layout-group-header-hidden > hr {
  display: none;
}

.ng-dynamic-forms-array.form-group {
  margin-bottom: 0;
}

.dynamic-form-array-item-label {
  border-bottom: 1px solid #a8a8a8;
  color: #444034;
  font-family: 'Montserrat', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.15em;
  padding-bottom: 5px;
  width: 100%;

  // ft-target
  &::before {
    font-family: feather;
    content: '\e9c1';
  }

  .required {
    margin-left: 5px;

    &::after {
      content: '';
    }
  }
}
//pwo

.ng-dynamic-forms-commissioned_audit_result {
  > label:first-of-type {
    display: none;
  }
}

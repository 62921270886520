@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

// Theme Colors
$theme-colors: (
  primary: #44d4b1,
  success: #44d4b1,
  info: #319dcb,
  warning: #c58e25,
  danger: #f4869a,
  secondary: #fdbd82,
  light: #9da0a7,
  dark: #3a404f,
);

// Spacing

$spacer: 1rem;

$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: $spacer,
  4: (
    $spacer * 1.5,
  ),
  5: (
    $spacer * 3,
  ),
) !default;

// Body
$body-bg: #f5f7fa;

// Fonts
$font-family-sans-serif: 'Inter', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$font-family-content: 'Rubik', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;

$font-size-root: 14px;

.table-container {
  position: relative;
  overflow: auto;

  &:after {
    content: '';
    background: url('/assets/icons/swipe.svg') no-repeat center/cover;
    width: 30px;
    height: 30px;
    display: block;
    animation: slideIn 2s infinite;
    position: absolute;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
}

cdk-table {
  display: block;
  position: relative;

  .sticky-action {
    position: sticky;
    right: -20px;
    width: auto !important;
    flex: 0;

    .row {
      flex-wrap: nowrap;
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }

  100% {
    opacity: 0.4;
    transform: translateX(-20px);
  }
}

cdk-paginator {
  &.cdk-paginator {
    padding-top: 40px !important;
  }
}

cdk-row {
  padding: 10px 0;
}

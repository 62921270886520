cdk-table {
  &.table-in-card {
    margin: 0 -9px;
  }

  cdk-header-row {
    border-top: 1px solid rgba(58, 64, 79, 0.2);
    border-bottom: 1px solid rgba(58, 64, 79, 0.2);
    padding: 24px 8px;
    font-weight: 600;
    align-items: center;
  }
  cdk-row {
    padding: 15px 8px;
    border-bottom: 1px solid rgba(58, 64, 79, 0.1);
  }
}

h1,
.h1 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0;
}

h2,
.h2 {
  font-weight: 600;
  margin-bottom: 24px;
}

h5,
.h5 {
  font-size: 20px;
  font-weight: 600;
}

.font-sm {
  font-size: 13px;
}

@import 'utils/variables';
.dashboard {
  &-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: $border-radius--lg;
    background-color: $color-surface-primary;
    padding: 24px;
    height: 100%;
  }

  &-icon {
    font-size: 22px;
    background: $color-surface-brand-2;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    border-radius: 50%;
  }

  &-title {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 400;
  }

  &-value {
    font-size: 20px;
    font-weight: 600;
  }
}

.contracted-product-delivery-host {
  .form-group {
    margin-bottom: 0;
  }
}

.product-delivery-checkbox {
  margin-left: 20px;
  margin-top: 35px;
  width: 125px;
  display: inline-block;
}

.product-delivery-form {
  display: inline-block;
  width: calc(100% - 210px);
}

.product-delivery-button {
  margin-top: 30px;
}

.product-delivery-amount-host {
  display: inline-block;
  margin-right: 15px;
  width: 115px;
}

.product-delivery-varieties-host {
  display: inline-block;
  margin-right: 15px;
  width: 435px;
}

.product-delivery-parcels-host {
  display: inline-block;
  margin-right: 15px;
  width: calc(100% - 595px);
}

.product-delivery-container {
  margin-bottom: 0;
}

$modal-width: 600px;
$modal-width-wide: 800px;
$modal-start-right-position: -400px;

.modal-title {
  font-size: 22px;
  font-weight: 600;
}

.modal.right {
  animation: fade-in-right 0.3s 1;
}

@-webkit-keyframes fade-in-right {
  0% {
    opacity: 0;
    right: $modal-start-right-position;
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    right: 0;
  }
}

@-moz-keyframes fade-in-right {
  0% {
    opacity: 0;
    right: $modal-start-right-position;
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0px);
  }
}

@-o-keyframes fade-in-right {
  0% {
    opacity: 0;
    right: $modal-start-right-position;
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0px);
    right: 0;
  }
}

@keyframes fade-in-right {
  0% {
    opacity: 0;
    right: $modal-start-right-position;
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
    right: 0;
  }
}

.modal-dialog {
  max-width: $modal-width;
  margin: 10vh auto;
}

.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: $modal-width;
  height: 100%;
  min-height: 100vh;
}

.modal.right.wide .modal-dialog {
  max-width: $modal-width-wide;
}

.modal.right .modal-content {
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
  border-radius: 0;
  border: none;
}

.modal.right .modal-body {
  padding: 24px;
}

.modal.right.fade .modal-dialog {
  right: 0;
}

.modal-content .modal-footer .btn + .btn {
  margin-left: 0;
}

.modal-content {
  border-radius: $border-radius;
  border: none;
  box-shadow: $box-shadow;
}

.close {
  font-family: 'Phosphor';
  content: '\e9e6';
  font-size: 26px;
  opacity: 1;

  &:focus {
    outline: none;
  }
}

.modal-footer {
  gap: 12px;
}
